import { ApolloError } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from "@unform/web";
import { FormRow } from 'components/Forms/styles';
import TextInput from 'components/TextInput';
import { IRegisterSupplierFormData, IRegisterSupplierFormProps } from 'pages/StockSuppliers/constants/types';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { applyMask, extractNumbersFromMaskedStrings } from 'shared/utils/masks';
import { ValidationError } from 'yup';
import { RegisterSupplierFormId, RegisterSupplierFormValidationSchema } from './constants';

const RegisterSupplierForm: React.FC<IRegisterSupplierFormProps> = ({ onSubmit }) => {
	const [cnpj, setCnpj] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>("");

	const registerSupplierFormRef = useRef<FormHandles>(null);

	const handleCnpjChange = (event: ChangeEvent<HTMLInputElement>) => {
		const formattedCnpj = applyMask(event.target.value, "cnpj");
		setCnpj(formattedCnpj);
	};

	const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
		const formattedNumber = applyMask(event.target.value, "phone");
		setPhoneNumber(formattedNumber);
	};

	const validateFields = useCallback(async (
		payload: IRegisterSupplierFormData,
		options: { reset: () => void }
	) => {
		payload.cnpj = extractNumbersFromMaskedStrings(payload.cnpj);
		payload.phoneNumber = extractNumbersFromMaskedStrings(payload.phoneNumber);
		try {
			await RegisterSupplierFormValidationSchema.validate(payload, { abortEarly: false });
			await onSubmit(payload, options);
			options.reset();
			setCnpj("");
			setPhoneNumber("");
			registerSupplierFormRef.current?.setErrors({});
			launchToast("Fornecedor registrado com sucesso!", "success");
		} catch (error) {
			if (error instanceof ValidationError) {
				const errors = getValidationErrors(error);
				registerSupplierFormRef.current?.setErrors(errors);
			} else if (error instanceof ApolloError) {
				launchToast(error.message, "error");
			} else {
				launchToast("Erro desconhecido. Contate o suporte", "error");
			}
		}
	}, []);

	return (
		<>
			<Form onSubmit={validateFields} ref={registerSupplierFormRef} id={RegisterSupplierFormId}>
				<FormRow>
					<TextInput
						label="nome"
						name="name"
						placeholder="Ex: XYZ LTDA"
						type="text"
					/>
					<TextInput
						label="cnpj"
						name="cnpj"
						placeholder="Ex: 99999999999999"
						value={cnpj}
						onChange={handleCnpjChange}
					/>
					<TextInput
						label="email"
						name="email"
						placeholder="Ex: fornecedor@email.com"
						type="text"
					/>
					<TextInput
						label="telefone"
						name="phoneNumber"
						placeholder="Ex: 11999999999"
						type="text"
						value={phoneNumber}
						onChange={handlePhoneNumberChange}
					/>
				</FormRow>
			</Form >
		</>
	)
}

export default RegisterSupplierForm