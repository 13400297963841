import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import { FormContainer } from 'components/Forms/styles';
import Header from 'components/Header';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Table from 'components/Table';
import { CREATE_TRUCK, GET_TRUCKS } from 'pages/StockTrucks/constants/graphQL';
import React from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { trucksTableHeaders } from './constants';
import { ITruckBase } from './constants/types';
import CreateTruckForm from './forms/createTruck';
import { CreateTruckFormId } from './forms/createTruck/constants';

function StockTrucks() {
  const { data, loading: queryLoading } = useQuery<{ trucks: ITruckBase[] }>(GET_TRUCKS);
  const [createTrucks, { loading }] = useMutation(CREATE_TRUCK, {
    refetchQueries: [GET_TRUCKS],
  });

  return (
    <MainContainer>
      <Header title="TRUCKS" />
      <FormContainer>
        <CreateTruckForm
          onSubmit={(data) => createTrucks({ variables: { input: data } })}
        />
        <Button
          icon={IoIosAddCircleOutline}
          text="Criar"
          styleContainer={{
            justifyContent: 'flex-end',
            margin: '0px 0px 24px 0px',
            alignSelf: 'end',
            flexGrow: 1,
          }}
          type="submit"
          loading={loading}
          form={CreateTruckFormId}
        />
      </FormContainer>
      {queryLoading && <Loading />}
      {data && (
        <Table
          data={data.trucks}
          headers={trucksTableHeaders}
        />
      )}
    </MainContainer>
  );
}

export default StockTrucks;
