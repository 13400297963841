import { gql } from "@apollo/client";

export const GET_MOVEMENTS = gql`
  query StockMovements($input: ListStockMovementsInputType!) {
    stockMovements(input: $input) {
      extendedMovements {
        id
        actionType
        quantity
        movementDate
        source {
          id
          name
          type
        }
        recipient {
          id
          name
          type
        }
        user {
          id
          name
        }
        receiptProduct {
          product {
            id
            name
          }
        }
      }
      totalPages
    }
  }
`;
