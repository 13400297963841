import React from "react";
import { Switch } from "react-router-dom";

import SignIn from "../pages/SignIn";
import PasswordRecovery from "../pages/PasswordRecovery";
import ChangePassword from "../pages/ChangePassword";
import UpdatePassword from "../pages/UpdatePassword";
import UserPanel from "../pages/Users";
import Dashboard from "../pages/Dashboard";
import ProfilePanel from "../pages/ProfilePanel";
import Orders from "../pages/Orders";
import NotFound from "../pages/NotFound";
import Appointments from "../pages/Appointments";
import NewUsers from "../pages/report/newUsers";
import Coupon from "../pages/Coupon";
import LastAppointment from "../pages/report/petLastAppointment";
import AppointmentsReport from "../pages/report/AppointmentsReport";
import AppointmentsFinancialReport from "../pages/report/AppointmentsFinancialReport";
import Transactions from "../pages/Transactions";
import RouteDashboard from "../pages/RouteDashboard";

import SubscriptionsManager from "../pages/SubscriptionsManager";
import ProfessionalsSupplies from "../pages/ProfessionalsSupplies";
import Zones from "../pages/Zones";
import Pets from "../pages/Pets";

import Route from "./Route";
import { Partners } from "pages/Partners";
import { Condominiums } from "pages/Condominiums";

import Events from "pages/Events";
import Campaigns from "pages/Campaigns";
import Receipts from "pages/Receipts";
import Moviment from "pages/Moviment";
import MovimentsReport from "pages/report/MovimentsReport";
import Stock from "pages/Stock";
import Ceps from "pages/Ceps";
import Anamnesis from "pages/Anamnesis";
import OrdersErrors from "pages/Orders/OrdersErrors";
import ProfessionalReport from "pages/ProfessionalReport";
import StockPanel from "pages/StockPanel";
import StockSuppliers from "pages/StockSuppliers";
import StockOutputReasons from "pages/StockOutputReasons";
import StockAccountAssignments from "pages/StockAccountAssignments";
import StockTrucks from "pages/StockTrucks";
import StockProductCategories from "pages/StockProductCategories";
import StockWarehouses from "pages/StockWarehouses";
import CreateWarehouse from "pages/StockWarehouses/CreateWarehouse";
import StockProducts from "pages/StockProducts";
import CreateProduct from "pages/StockProducts/CreateProduct";
import CreateReceipt from "pages/Receipts/CreateReceipt";
import PurchasedProducts from "pages/PurchasedProducts";
import StockMovements from "pages/Movements";


//SE MUDAR OS NOME PRECISA CORRIGIR A TABBAR

const Routes: React.FC = () => (
  <>
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/recovery" exact component={PasswordRecovery} />
      <Route path="/changepassword" exact component={ChangePassword} />
      <Route
        path="/updatepassword"
        exact
        component={UpdatePassword}
        isPrivate
      />
      <Route path="/home" exact component={Dashboard} isPrivate sidebar />
      <Route path="/stock-panel" exact component={StockPanel} isPrivate sidebar />
      <Route path="/stock-trucks" exact component={StockTrucks} isPrivate sidebar />
      <Route path="/stock-output-reasons" exact component={StockOutputReasons} isPrivate sidebar />
      <Route path="/stock-suppliers" exact component={StockSuppliers} isPrivate sidebar />
      <Route path="/stock-warehouses" exact component={StockWarehouses} isPrivate sidebar />
      <Route path="/stock-warehouses/create" exact component={CreateWarehouse} isPrivate sidebar />
      <Route path="/stock-products-categories" exact component={StockProductCategories} isPrivate sidebar />
      <Route path="/stock-products" exact component={StockProducts} isPrivate sidebar />
      <Route path="/stock-products/create" exact component={CreateProduct} isPrivate sidebar />
      <Route path="/stock-accounts-assignments" exact component={StockAccountAssignments} isPrivate sidebar />
      <Route path="/receipts" exact component={Receipts} isPrivate sidebar />
      <Route path="/receipts/create" exact component={CreateReceipt} isPrivate sidebar />
      <Route path="/purchased-products" exact component={PurchasedProducts} isPrivate sidebar />
      <Route path="/movements" exact component={StockMovements} isPrivate sidebar />
      <Route path="/profile" exact component={ProfilePanel} isPrivate sidebar />
      <Route path="/users" exact component={UserPanel} isPrivate sidebar />
      <Route path="/pets" exact component={Pets} isPrivate sidebar />
      <Route path="/anamnesis" exact component={Anamnesis} isPrivate sidebar />
      <Route path="/professional-report" exact component={ProfessionalReport} isPrivate sidebar />
      <Route
        path="/appointment"
        exact
        component={Appointments}
        isPrivate
        sidebar
      />
      <Route path="/orders" exact component={Orders} isPrivate sidebar />
      <Route path="/orders-errors" exact component={OrdersErrors} isPrivate sidebar />
      <Route
        path="/transactions"
        exact
        component={Transactions}
        isPrivate
        sidebar
      />
      <Route path="/coupon" exact component={Coupon} isPrivate sidebar />
      <Route path="/event" exact component={Events} isPrivate sidebar />
      <Route path="/campaigns" exact component={Campaigns} isPrivate sidebar />
      <Route path="/newUsers" exact component={NewUsers} isPrivate sidebar />
      <Route
        path="/appointments-reports"
        exact
        component={AppointmentsReport}
        isPrivate
        sidebar
      />
       <Route
        path="/appointments-financial-reports"
        exact
        component={AppointmentsFinancialReport}
        isPrivate
        sidebar
      />
      <Route
        path="/moviments-report"
        exact
        component={MovimentsReport}
        isPrivate
        sidebar
      />
      <Route
        path="/professionals-supplies"
        exact
        component={ProfessionalsSupplies}
        isPrivate
        sidebar
      />

      <Route
        path="/lastAppointment"
        exact
        component={LastAppointment}
        isPrivate
        sidebar
      />
      <Route
        path="/route-dashboard"
        exact
        component={RouteDashboard}
        isPrivate
        sidebar
      />
      <Route path="/zonas" exact component={Zones} isPrivate sidebar />
      <Route path="/setores" exact component={Ceps} isPrivate sidebar />
      <Route
        path="/plans"
        exact
        component={SubscriptionsManager}
        isPrivate
        sidebar
      />
      <Route path="/parceiros" exact component={Partners} isPrivate sidebar />
      <Route
        path="/condominios"
        exact
        component={Condominiums}
        isPrivate
        sidebar
      />
      <Route component={NotFound} notFounded />
    </Switch>

    {/* <Route path="/signup-customer" exact component={SignUpCustomer} isPrivate /> */}
  </>
);

export default Routes;
