import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import { FormContainer } from 'components/Forms/styles';
import Header from 'components/Header';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Table from "components/Table";
import RegisterSupplierForm from 'pages/StockSuppliers/forms/registerSupplier';
import React from 'react';
import { IoIosAddCircleOutline } from "react-icons/io";
import { GET_SUPPLIERS, REGISTER_SUPPLIERS } from './constants/graphQL';
import { suppliersTableHeaders } from './constants/headerColumns';
import { ISupplierBase } from './constants/types';
import { RegisterSupplierFormId } from './forms/registerSupplier/constants';

export default function StockSuppliers() {
  const { data, loading: queryLoading } = useQuery<{ suppliers: ISupplierBase[] }>(GET_SUPPLIERS);
  const [registerSuppliers, { loading }] = useMutation(REGISTER_SUPPLIERS, {
    refetchQueries: [GET_SUPPLIERS],
  });

  return (
    <MainContainer>
      <Header title="FORNECEDORES" />
      <FormContainer>
        <RegisterSupplierForm
          onSubmit={(data) => registerSuppliers({ variables: { input: data } })}
        />
        <Button
          icon={IoIosAddCircleOutline}
          text="Criar"
          styleContainer={{ width: '100%', justifyContent: 'flex-end', margin: '12px 12px 0px 0px' }}
          type="submit"
          loading={loading}
          form={RegisterSupplierFormId}
        />
      </FormContainer>
      {queryLoading && <Loading />}
      {data && (
        <Table
          data={data.suppliers}
          headers={suppliersTableHeaders}
        />
      )}
    </MainContainer>
  );
}
