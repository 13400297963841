import { useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from "@unform/web";
import { FormField, FormRow } from 'components/Forms/styles';
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { IAddProductToReceiptFormProps, IProductReceiptFormData } from 'pages/Receipts/constants/types';
import { GET_PRODUCTS } from 'pages/StockProducts/constants/graphQL';
import { IExtendedProduct, IProductsResponse, IProductStockUnitTranslation } from 'pages/StockProducts/constants/types';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { applyMask, extractNumbersFromMaskedStrings } from 'shared/utils/masks';
import { ValidationError } from 'yup';
import { AddProductToReceiptFormId, AddProductToReceiptValidationSchema } from './constants';

const AddProductToReceiptForm: React.FC<IAddProductToReceiptFormProps> = ({
  onSubmit
}) => {
  const [selectedProduct, setSelectedProduct] = useState<any>(); 
  const [stockUnity, setstockUnity] = useState(""); 
  const [unityPrice, setUnityPrice] = useState("R$ 0,00");
  const [resalePrice, setResalePrice] = useState("R$ 0,00");

  const addProductToReceiptFormRef = useRef<FormHandles>(null);

  const { data } = useQuery<{ products: IProductsResponse }>(GET_PRODUCTS);
 
  useEffect(() => {
    if (selectedProduct) {
      setstockUnity(IProductStockUnitTranslation[(selectedProduct as IExtendedProduct).stockUnit]);
    }
  }, [selectedProduct]);

  const handleUnityPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
		const formattedUnityPrice = applyMask(event.target.value, "money");
		setUnityPrice(formattedUnityPrice);
	};

  const handleResalePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
		const formattedResalePrice = applyMask(event.target.value, "money");
		setResalePrice(formattedResalePrice);
	};

  const resetForm = () => {
    setUnityPrice("R$ 0,00");
    setResalePrice("R$ 0,00");
    setstockUnity("");
    addProductToReceiptFormRef.current?.setData({
      quantity: "",
      productId: "",
    });
  }

  const handleSubmit = async (
    payload: IProductReceiptFormData,
  ) => {
    try {
      payload.unityPrice = Number(extractNumbersFromMaskedStrings(String(payload.unityPrice))) || undefined;
      payload.resalePrice = Number(extractNumbersFromMaskedStrings(String(payload.resalePrice))) || undefined;
      payload.quantity = Number(payload.quantity) || undefined;
      await AddProductToReceiptValidationSchema.validate(payload, { abortEarly: false });
      onSubmit({ ...payload, name: selectedProduct.name });
      resetForm();
      addProductToReceiptFormRef.current?.setErrors({});
    } catch (error) {
      if(error instanceof ValidationError){
        const errors = getValidationErrors(error);
        addProductToReceiptFormRef.current?.setErrors(errors);
      } else {
        launchToast("Erro desconhecido. Contate o suporte", "error");
      }
    }
  };

  return (
    <Form 
      onSubmit={handleSubmit}
      ref={addProductToReceiptFormRef}
      id={AddProductToReceiptFormId}
    >
      <FormRow>
        <FormField>
          <Label>produto</Label>
          <Select
            name="productId"
            options={data?.products.products.map((product) => ({
              ...product,
              value: product.id,
              label: product.name
            }))}
            placeholder="Selecione um produto"
            onChange={(product) => setSelectedProduct(product)}
          />
        </FormField>
        <TextInput
          label="unidade de medida"
          name="stockUnit"
          placeholder="Selecione um Produto"
          value={stockUnity}
          disabled
        />
      </FormRow>
      <FormRow>
        <TextInput
          label="quantidade"
          name="quantity"
          placeholder="Digite a quantidade"
          type="number"
        />
        <TextInput
          label="valor de compra"
          name="unityPrice"
          placeholder="Digite o valor"
          value={unityPrice}
          onChange={handleUnityPriceChange}
        />
        <TextInput
          label="valor de venda"
          name="resalePrice"
          placeholder="Digite o valor"
          value={resalePrice}
          onChange={handleResalePriceChange}
        />
      </FormRow>
    </Form >
  )
}

export default AddProductToReceiptForm
