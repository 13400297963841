import React from "react";
import TypePaymentEnum from "../../shared/enums/TypePaymentEnum";
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import { format } from "date-fns";

export type IAction = {
  name: string;
  icon: any;
  action: (id: string, data: any) => void;
};

type IHeader = {
  key: string;
  name: string;
};

interface IProps {
  headers: any[];
  data: any[]; 
  firstLine?: boolean;
  fitContentHeight?: boolean
}
const formatDate = (date: string) => {
  const dateFormatted = format(new Date(date), "dd/MM/yyyy HH:mm");
  return dateFormatted;
};

const CustomTable: React.FC<IProps> = ({ headers, data, firstLine = false, fitContentHeight }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      width="calc(100% - 20px)"
      overflowX="auto"
      height={fitContentHeight ? '' : isMobile ? "auto" : "80%"}
      overflowY="auto"
      marginY={4}
      marginX="auto"
    >
      <Table variant={ firstLine ? "unstyled" : "striped"}  borderRadius="md" boxShadow="md">
        <Thead
          backgroundColor="gray.50"
          borderBottom="1px solid"
          borderBottomColor="gray.200"
          position={{ base: "relative", md: "sticky" }}
          top={-4}
        >
          <Tr>
            {headers.map((header) => (
              <Th
                textAlign={"center"}
                key={header.key}
                textTransform="uppercase"
                color="gray.500"
                fontSize={14}
              >
                {header.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row: any, index: number) => (
          <Tr key={index} textAlign="center" backgroundColor={ firstLine && index === 0 ? "green.100" : (index % 2 === 1 ? "white" : "gray.100")} fontWeight={ index === 0 && firstLine ? "bold" : "normal"}>
              {headers.map((header: IHeader) => {
                if (header.key === "actions") {
                  return (
                    <Td
                      key={header.key}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      css={isMobile && { wordBreak: "break-word" }}
                    >
                      <Flex
                        gridGap="0.5rem"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {row.actions.map(
                          (action: IAction, index_column: number) => (
                            <Tooltip
                              key={action.name}
                              label={action.name}
                              placement="top"
                              hasArrow
                              fontSize={14}
                            >
                              <Button
                                onClick={() => {
                                  action.action(row.id, data);
                                }}
                                ml={index_column > 0 ? 4 : 0}
                              >
                                {action.icon}
                              </Button>
                            </Tooltip>
                          )
                        )}
                      </Flex>
                    </Td>
                  );
                }
                return (
                  <Td
                    key={header.key}
                    fontSize={14}
                    textAlign="center"
                    css={isMobile && { wordBreak: "break-word" }}
                  >
                    {header.key === "tutors"
                      ? row.tutors.map((t: any) => t.name).join(", ")
                      : header.key === "category"
                      ? row.category.name
                      : header.key === "created_at" && header.name === "criação"
                      ? row.created_at
                      : header.key === "customer_name"
                      ? row.order.customer.user.name
                      : header.key === "type_transaction"
                      ? TypePaymentEnum[row.type]
                      : typeof row[header.key] === 'object' && 'name' in row[header.key]
                      ? row[header.key].name
                      : row[header.key]}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CustomTable;
