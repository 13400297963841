export const stockProductsColumnsHeader = [
  { key: 'sku', name: 'sku' },
  { key: 'product', name: 'produto' },
  { key: 'quantity', name: 'quantidade' },
  { key: 'local', name: 'local' },
  { key: 'status', name: 'status' },
  { key: 'createdAt', name: 'ultima atualização' },
  { key: 'actions', name: 'ações' },
];

export const defaultSelectedRecipient = {
  value: "",
  label: "Selecione um destino"
};
