import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import { FormContainer } from 'components/Forms/styles';
import Header from 'components/Header';
import MainContainer from 'components/MainContainer';
import React from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { CREATE_WAREHOUSE, GET_WAREHOUSES } from './constants/graphQL';
import CreateWarehouseForm from './forms/createWarehouse';
import { CreateWarehouseFormId } from './forms/createWarehouse/constants';

function CreateWarehouse() {
  const [createWarehouse, { loading }] = useMutation(CREATE_WAREHOUSE, {
    refetchQueries: [GET_WAREHOUSES],
  });

  return (
    <MainContainer>
      <Header title="CRIAR UM ARMAZEM" hasGoBackButton />
      <FormContainer>
        <CreateWarehouseForm
          onSubmit={(data) => createWarehouse({ variables: { input: data } })}
        />
        <Button
          icon={IoIosAddCircleOutline}
          text="Criar"
          styleContainer={{
            justifyContent: 'flex-end',
            margin: '0px 0px 24px 0px',
            alignSelf: 'end',
            flexGrow: 1,
          }}
          type="submit"
          loading={loading}
          form={CreateWarehouseFormId}
        />
      </FormContainer>
    </MainContainer>
  )
}

export default CreateWarehouse;
