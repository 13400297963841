export const priceMaskInput = (e: any) => {
  const inputValue = e.target.value;
  const numericValue = inputValue.replace(/\D/g, '');
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(numericValue) / 100);
  return formattedValue;
};

export const priceMask = (value: string | number) => {
  const stringValue = value.toString().trim();
  const isNegative = stringValue.includes('-');
  const onlyDigits = stringValue.replace(/\D/g, '');
  let numericValue = Number(onlyDigits) / 100;
  if (isNegative) {
    numericValue = -numericValue;
  }
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(numericValue);
};

export const priceUnmask = (value: string): number => {
  if (!value) return 0;
  const formattedValue = value
    .replace('R$', '')
    .replace(/\./g, '')
    .replace(',', '');
  const numberFloat = parseFloat(formattedValue);
  return numberFloat / 100;
};

export const normalizeDate = (date: Date): Date => {
  const updatedDate = new Date(date);
  updatedDate.setHours(updatedDate.getHours() + 3);
  return updatedDate;
};

export const formatDate = (date: Date): string => {
  const normalizedDate = normalizeDate(date);
  const day = String(normalizedDate.getDate()).padStart(2, '0');
  const month = String(normalizedDate.getMonth() + 1).padStart(2, '0');
  const year = normalizedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const toBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      resolve(base64data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
