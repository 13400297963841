import * as Yup from "yup";

export const CreateReceiptFormValidationSchema = Yup.object().shape({
	receipt: Yup.object().shape({
		number: Yup.string()
			.typeError('Digite apenas numeros')
			.required('Numero é obrigatório')
			.max(6, "Máximo de 6 digitos "),
		emissionDate: Yup.date()
			.typeError('Escolha uma data')
			.required('Data de emissão é obrigatória'),
		estimatedDeliveryDate: Yup.date()
			.typeError('Escolha uma data')
			.required('Previsão de entrega é obrigatória'),
		supplierId: Yup.string()
			.required('Fornecedor é obrigatório'),
		warehouseId: Yup.string()
			.typeError('Destino é obrigatório')
			.required('Destino é obrigatório'),
	}),
});

export const CreateReceiptFormId = 'create-receipt-form';
