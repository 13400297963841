import { FormHandles, Scope } from '@unform/core';
import TextInput from 'components/TextInput';
import { FormRow } from 'components/Forms/styles';
import React, { ChangeEvent, useState } from 'react';
import { cepAutoComplete } from 'shared/utils/cepAutoComplete';
import { applyMask } from 'shared/utils/masks';

export type ICreateAddressFormProps = {
  formRef: React.RefObject<FormHandles>
}

function CreateAddressForm({ formRef }: ICreateAddressFormProps) {
  const [cep, setCep] = useState<string>("");

  const handleCepChange = (event: ChangeEvent<HTMLInputElement>) => {
		const formattedCep = applyMask(event.target.value, "cep");
		setCep(formattedCep); 
	};

  return (
    <Scope path="address">
      <FormRow>
        <TextInput
          label="cep"
          placeholder="00000-000"
          name="cep"
          type="text"
          onBlur={() => cepAutoComplete(formRef.current)}
          onChange={handleCepChange}
          value={cep}
        />
        <TextInput
          label="endereço"
          placeholder="Digite o endereço"
          name="street"
          type="text"
        />
        <TextInput
          label="número"
          placeholder="ex: 100"
          name="number"
          type="text"
          maxLength={10}
          width="10px"
        />
        <TextInput
          label="complemento"
          placeholder="Ex: AP 201, Casa 10"
          name="complement"
          type="text"
        />
      </FormRow>
      <FormRow>
        <TextInput
          label="cidade"
          placeholder="Digite a cidade"
          name="city"
          type="text"
        />
        <TextInput
          label="estado"
          placeholder="Ex: SP"
          name="state"
          type="text"
          maxLength={2}
        />
        <TextInput
          label="bairro"
          placeholder="Digite o nome do bairro"
          name="neighborhood"
          type="text"
        />
        <TextInput
          label="apelido"
          placeholder="Ex: casa"
          name="surname"
          type="text"
        />
      </FormRow>
    </Scope>
  )
}

export default CreateAddressForm;
