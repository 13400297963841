import { gql } from "@apollo/client";
import client from "services/api-graphql";

export const CREATE_PROFESSIONAL_REPORT = gql`
    query ProfessionalReport($input: GetProfessionalReportInputType!) {
        professionalReport(input: $input) {
            grossEarning
            grossPixPayment
            grossCreditCardPayment
            averageTicket
            calendarDays
            operationalDays
            amountOfAppointments
            discounts {
            key
            value
            multiplier
            discount
            }
            totalDiscount
            otherValues {
            description
            amount
            }
            earningAfterDiscount
            totalOtherValues
            netEarning
            netPixPayment
            netCreditCardPayment
            totalWithoutZazuuTax
            appointmentsData {  
                date                
                customer
                pet
                serviceName
                price
            }
        }
    }
`;


export const listOptionProfessional = async (input: string) => {
  const variables = {
    filters: {
      pagination: {
        limit: 6,
        page: 1
      },
      where: {
        name: input,
        status: true
      }
    }
  };
  const { data } = await client.query({
    query: gql`
        query Professionals($filters: FiltersProfessionalInputType) {
            professionals(filters: $filters) {
                id
                user {
                    name
                    roles {
                      id
                    }
                }
            }
        }
      `,
    variables,
    fetchPolicy: 'network-only',
  });
  return data.professionals
};