
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import { FormContainer } from 'components/Forms/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import React, { useMemo, useState } from 'react';
import colors from 'shared/utils/constants/colors';
import { launchToast } from 'shared/utils/launchToast';
import { GET_RECEIPTS, GOODS_RECEIPT } from '../constants/graphQL';
import * as Styles from '../constants/styles';
import { IExtendedReceipt } from '../constants/types';
import UpdateReceiptsForm from '../forms/UpdateReceipt';
import { UpdateReceiptFormId } from '../forms/UpdateReceipt/constants';

export type IUpdateReceiptsModalProps = {
	isOpen: boolean;
	onClose: () => void;
	receipt: IExtendedReceipt
};

const UpdateReceiptsModal: React.FC<IUpdateReceiptsModalProps> = ({
	isOpen,
	onClose,
	receipt
}) => {
	const [goodsReceipt, { loading }] = useMutation(GOODS_RECEIPT, {
		refetchQueries: [GET_RECEIPTS],
	});

	const [actualReceiptProducts, setActualReceiptProducts] = useState(receipt.receiptProducts);

	const isDivergenceReceipt = useMemo(() => {
		return actualReceiptProducts.some((actualReceiptProduct) => {
			const findedReceiptProduct = receipt.receiptProducts.find((originalReceiptProduct) => actualReceiptProduct.id === originalReceiptProduct.id);
			return findedReceiptProduct?.quantity !== actualReceiptProduct.quantity;
		});
	}, [actualReceiptProducts]);

	const handleDevolution = async () => {
		await goodsReceipt({ variables: {
			input: {
				id: receipt.id,
				status: 'Devolvida',
				products: receipt.receiptProducts.map((receiptProduct) => ({
					id: receiptProduct.product.id,
					name: receiptProduct.product.name,
					stockUnit: receiptProduct.product.stockUnit,
					quantity: receiptProduct.quantity,
				}))
			}
		}});
		onClose();
		launchToast("Nota atualizada com sucesso", "success");
	};

	return (
		<Modal visible={isOpen} closeModal={onClose}>
			<Styles.header>
				<Text
					text="Editar Nota Fiscal"
					color={colors.argon.darkBlue}
					fontFamily="Open Sans"
					size={17}
					weight="600"
				/>
			</Styles.header>
			<FormContainer>
				<UpdateReceiptsForm
					onSubmit={(updatedProducts) => goodsReceipt({ variables: {
						input: {
							id: receipt.id,
							status: isDivergenceReceipt ? 'Divergente' : 'Confirmada',
							updatedProducts,
						}
					}})}
					receipt={receipt}
					onQuantityChange={(data) => setActualReceiptProducts(data)}
					onClose={onClose}
				/>
			</FormContainer>
			<Styles.rowButton>
				<Button
					text="Cancelar"
					onClick={onClose}
				/>
				<Button
					text="Devolver"
					loading={loading}
					onClick={handleDevolution}
					title="Selecione essa opção se os produtos estão sendo devolvidos"
				/>
				<Button
					text="Salvar"
					type="submit"
					form={UpdateReceiptFormId}
					loading={loading}
				/>
			</Styles.rowButton>
		</Modal>
	)
}

export default UpdateReceiptsModal;
