import React, { useCallback, useRef, useState } from 'react';
import Button from 'components/Button';
import * as Styles from "./constants/styles";
import { ICreateProfessionalReportFormData } from './constants/types';
import CreateProfessionalReportpForm, { CreateProfessionalReportFormId } from './CreateProfessionalReportpForm';
import { PanelHeader } from 'components/PanelHeader';
import SelectProfessionalModal from './SelectReportModal';
import { useDisclosure } from '@chakra-ui/react';

const ProfessionalReportModal: React.FC = () => {
  const [dataToGerate, setDataToGerate] = useState([] as any)
  const selectReportProfessionalModalControl = useDisclosure();

  const handleSubmit = useCallback(
    async (payload: ICreateProfessionalReportFormData, { reset }) => {

      setDataToGerate(payload)
      selectReportProfessionalModalControl.onOpen()

    },
    [dataToGerate],
  );

  return (
    <>
      <SelectProfessionalModal
           isOpen={selectReportProfessionalModalControl.isOpen}
           onClose={selectReportProfessionalModalControl.onClose}
           payload={dataToGerate}
      />
      <Styles.MainContainer>
        <PanelHeader
          isFilterModalVisible={false}
          title="Relatório Financeiro de Profissional"
        />
        <Styles.FormDiv>
          <CreateProfessionalReportpForm onSubmit={handleSubmit} />
        </Styles.FormDiv>                                                                                                             
        <Styles.rowButton>
          <Button
            text="Gerar Relatório"
            type="submit"
            form={CreateProfessionalReportFormId}
          />
        </Styles.rowButton>
      </Styles.MainContainer>
    </>
  );
};

export default ProfessionalReportModal;