import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import { FormContainer } from 'components/Forms/styles';
import Header from 'components/Header';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Table from 'components/Table';
import { CREATE_ACCOUNT_ASSIGNMENT, GET_ACCOUNT_ASSIGNMENTS } from 'pages/StockAccountAssignments/constants/graphQL';
import React from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { accountAssignmentsTableHeaders } from './constants';
import { IAccountAssignmentBase } from './constants/types';
import CreateAccountAssignmentForm from './forms/createAccountAssignment';
import { CreateAccountAssignmentFormId } from './forms/createAccountAssignment/constants';

function StockAccountAssignments() {
  const { data, loading: queryLoading } = useQuery<{ accountAssignments: IAccountAssignmentBase[] }>(GET_ACCOUNT_ASSIGNMENTS);
  const [createAccountAssignments, { loading }] = useMutation(CREATE_ACCOUNT_ASSIGNMENT, {
    refetchQueries: [GET_ACCOUNT_ASSIGNMENTS],
  });

  return (
    <MainContainer>
      <Header title="CLASSIFICAÇÕES CONTABEIS" />
      <FormContainer>
        <CreateAccountAssignmentForm
          onSubmit={(data) => createAccountAssignments({ variables: { input: data } })}
        />
        <Button
          icon={IoIosAddCircleOutline}
          text="Criar"
          styleContainer={{
            justifyContent: 'flex-end',
            margin: '0px 0px 24px 0px',
            alignSelf: 'end',
            flexGrow: 1,
          }}
          type="submit"
          loading={loading}
          form={CreateAccountAssignmentFormId}
        />
      </FormContainer>
      {queryLoading && <Loading />}
      {data && (
        <Table
          data={data.accountAssignments}
          headers={accountAssignmentsTableHeaders}
        />
      )}
    </MainContainer>
  );
}

export default StockAccountAssignments;
