import { ApolloError, useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FormField, FormRow } from 'components/Forms/styles';
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { GET_ACCOUNT_ASSIGNMENTS } from 'pages/StockAccountAssignments/constants/graphQL';
import { IAccountAssignmentBase } from 'pages/StockAccountAssignments/constants/types';
import { GET_PRODUCT_CATEGORIES } from 'pages/StockProductCategories/constants/graphQL';
import { IProductCategoryBase } from 'pages/StockProductCategories/constants/types';
import { stockUnityOptions } from 'pages/StockProducts/constants';
import { GET_ITEMS, GET_PACKAGINGS } from 'pages/StockProducts/constants/graphQL';
import { ICreateProductFormData, ICreateProductFormProps, IItemsResponse, IPackagingsResponse } from 'pages/StockProducts/constants/types';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { ValidationError } from 'yup';
import { CreateProductFormId, CreateProductFormValidationSchema } from './constants';

function CreateProductForm({
  onSubmit
}: ICreateProductFormProps) {
  const createProductFormRef = useRef<FormHandles>(null);

  const history = useHistory();

  const { data: productCategoriesList } = useQuery<{ productCategories: IProductCategoryBase[] }>(GET_PRODUCT_CATEGORIES);
  const { data: packagingList } = useQuery<{ packagings: IPackagingsResponse[] }>(GET_PACKAGINGS);
  const { data: accountAssigmentsList } = useQuery<{ accountAssignments: IAccountAssignmentBase[] }>(GET_ACCOUNT_ASSIGNMENTS);
  const { data: itemsList } = useQuery<{ productItems: IItemsResponse[] }>(GET_ITEMS);


  const validateFields = async (
    payload: ICreateProductFormData,
    options: { reset: () => void }
  ) => {
    
    try {
      payload.accountAssignmentId = Number(payload.accountAssignmentId) || undefined;
      payload.multiplier = Number(payload.multiplier) || undefined;
      await CreateProductFormValidationSchema.validate(payload, { abortEarly: false });
      await onSubmit(payload);
      options.reset();
      createProductFormRef.current?.setErrors({});
      launchToast("Produto criado com sucesso!", "success");
      history.goBack();
    } catch (error) {
			if (error instanceof ValidationError) {
				const errors = getValidationErrors(error);
				createProductFormRef.current?.setErrors(errors);
			} else if (error instanceof ApolloError) {
				launchToast(error.message, "error");
			} else {
				launchToast("Erro desconhecido. Contate o suporte", "error");
			}
    }
  }

  return (
    <Form
      onSubmit={validateFields}
      ref={createProductFormRef}
      id={CreateProductFormId}
    >
      <FormRow>
        <TextInput
          label="nome"
          name="name"
          placeholder="Digite o nome"
        />
        <TextInput
          label="descrição"
          name="description"
          placeholder="Digite a descrição"
        />
        <TextInput
          label="código sku"
          name="manufacturerSkuCode"
          placeholder="Digite o código"
        />
      </FormRow>
      <FormRow>
        <FormField>
          <Label>unidade de medida</Label>
          <Select
            name="stockUnit"
            placeholder="Selecione a medida"
            options={stockUnityOptions}
          />
        </FormField>
        <FormField>
          <Label>embalagem</Label>
          <Select
            name="packagingId"
            placeholder="Selecione a embalagem"
            options={packagingList?.packagings.map((packaging) => ({
              label: packaging.name,
              value: packaging.id
            }))}
          />
        </FormField>
        <TextInput
          label="multiplicador"
          name="multiplier"
          placeholder="Digite o multiplicador"
          type="number"
        />
      </FormRow>
      <FormRow>
        <FormField>
          <Label>categoria</Label>
          <Select
            name="productCategoryId"
            placeholder="Selecione a categoria"
            options={productCategoriesList?.productCategories.map((category) => ({
              label: category.name,
              value: category.id
            }))}
          />
        </FormField>
        <FormField>
          <Label>classificação contábil</Label>
          <Select
            name="accountAssignmentId"
            placeholder="Selecione a categoria"
            options={accountAssigmentsList?.accountAssignments.map((accountAssignment) => ({
              label: accountAssignment.name,
              value: accountAssignment.id
            }))}
          />
        </FormField>
        <FormField>
          <Label>produto do app</Label>
          <Select
            name="itemId"
            placeholder="Digite o nome"
            options={itemsList?.productItems.map((item) => ({
              label: item.name,
              value: item.id
            }))}
          />
        </FormField>
      </FormRow>
    </Form>
  )
}

export default CreateProductForm;
