import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import { FormContainer } from 'components/Forms/styles';
import Header from 'components/Header';
import MainContainer from 'components/MainContainer';
import Table from "components/Table";
import React, { useState } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { MdDelete } from "react-icons/md";
import colors from 'shared/utils/constants/colors';
import convertToCurrencyFormat from 'shared/utils/convertToCurrencyFormat';
import { launchToast } from 'shared/utils/launchToast';
import { receiptProductsHeaderTable } from './constants';
import { CREATE_RECEIPT, GET_RECEIPTS } from './constants/graphQL';
import { IProductReceiptFormData } from './constants/types';
import AddProductToReceiptForm from './forms/addProductToReceipt';
import { AddProductToReceiptFormId } from './forms/addProductToReceipt/constants';
import CreateReceiptForm from './forms/createReceipt';
import { CreateReceiptFormId } from './forms/createReceipt/constants';

function CreateReceipt() {
  const [receiptProducts, setReceiptProducts] = useState<IProductReceiptFormData[]>([]);

  const handleAddProduct = (product: IProductReceiptFormData) => {
    const isProductExist = receiptProducts.some((existingProduct) => existingProduct.productId === product.productId);
    if (isProductExist) {
      launchToast('Produto já incluído', 'error');
    } else {
      setReceiptProducts([...receiptProducts, product]);
    }
  }

  const handleRemoveProduct = (productId: number) => {
    setReceiptProducts(receiptProducts.filter((product) => product.productId !== productId ));
  }

const [createReceipt, { loading }] = useMutation(CREATE_RECEIPT, {
    refetchQueries: [GET_RECEIPTS],
  });

  return (
    <MainContainer>
      <Header title="REGISTRAR NOTA FISCAL" />
      <FormContainer>
        <CreateReceiptForm
          onSubmit={(data) => createReceipt({ variables: { input: data } })}
          products={receiptProducts}
          setReceiptProducts={setReceiptProducts}
        />
        <FormContainer style={{ width: '100%', flexDirection: 'column' }}>
          <AddProductToReceiptForm
            onSubmit={handleAddProduct}
          />
          <Button
            text="Adicionar produto"
            styleContainer={{
              justifyContent: 'flex-end',
              margin: '0px 0px 24px 0px',
              alignSelf: 'end',
              flexGrow: 1,
            }}
            type="submit"
            form={AddProductToReceiptFormId}
          />
        </FormContainer>
        {receiptProducts.length > 0 && (
          <Table
            fitContentHeight
            headers={receiptProductsHeaderTable}
            data={receiptProducts.map((product) => ({
              ...product,
              product: product.name,
              unityPrice: convertToCurrencyFormat(product.unityPrice as number / 100),
              resalePrice: convertToCurrencyFormat(product.resalePrice as number / 100),
              actions: [
                {
                  name: "remove",
                  icon: <MdDelete size={20} color={colors.suport.alert} />,
                  action: () => handleRemoveProduct(product.productId),
                },
              ],
            }))}
          />
        )}
        <Button
          icon={IoIosAddCircleOutline}
          text="Registrar nota"
          styleContainer={{
            justifyContent: 'flex-end',
            margin: '0px 0px 24px 0px',
            alignSelf: 'end',
            flexGrow: 1,
          }}
          type="submit"
          loading={loading}
          form={CreateReceiptFormId}
        />
      </FormContainer>
    </MainContainer>
  )
}

export default CreateReceipt;
