import { gql } from "@apollo/client";

export const GET_PURCHASED_PRODUCTS = gql`
  query StockProducts($input: ListStockProductsInputType!) {
    stockProducts(input: $input) {
      data {
        id
        quantity
        createdAt
        local {
          id
          name
          type
        }
        receiptProduct {
          id
          product {
            id
            name
            manufacturerSkuCode
          }
          receipt {
            id
            status
          }
        }
      }
      totalPages
    }
  }
`;

export const MOVE_PRODUCT = gql`
  mutation Mutation($input: MoveProductInputType!) {
    moveProduct(input: $input)
  }
`;
