import styled from "styled-components";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const ModalButtonsWrapper = styled.div <{ margin?: string }>`
  display: flex;
  justify-content:space-between;
  margin-top: 24px;
  gap: 24px;

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: flex-end;
    width: 30%;
    margin-left:${props => `${props?.margin}%` || "70%"};
  }
`;
