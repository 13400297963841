import { useQuery } from '@apollo/client';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Pagination from 'components/Pagination';
import { PanelHeader } from 'components/PanelHeader';
import Table from "components/Table";
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { BiTransfer } from "react-icons/bi";
import { stockProductsColumnsHeader } from './constants';
import { GET_PURCHASED_PRODUCTS } from './constants/graphQL';
import { IPurchasedProduct, IStockProductsResponse } from './constants/types';
import MoveProductModal from './modals/MoveProduct';
import { useDisclosure } from '@chakra-ui/react';

function PurchasedProducts() {
  const [paginationLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedProduct, setSelectedProduct] = useState<IPurchasedProduct>();

  const moveProductModalControl = useDisclosure();

  const { data, loading } = useQuery<IStockProductsResponse>(GET_PURCHASED_PRODUCTS, {
    variables: {
      input: {
        pagination: {
          limit: paginationLimit,
          page: currentPage,
        }
      }
    }
  });

  const handleMoveProduct = (product: IPurchasedProduct) => {
    setSelectedProduct(product);
    moveProductModalControl.onOpen();
  }

  return (
    <MainContainer>
      {selectedProduct && (
        <MoveProductModal
          isOpen={moveProductModalControl.isOpen}
          onClose={moveProductModalControl.onClose}
          product={selectedProduct}
        />
      )}
      <PanelHeader
        title="Produtos comprados"
        isFilterModalVisible={false}
      />
      {loading && <Loading />}
      {data && (
        <>
          <Table
            headers={stockProductsColumnsHeader}
            data={data.stockProducts.data.map((product) => ({
              ...product,
              sku: product.receiptProduct.product.manufacturerSkuCode,
              product: product.receiptProduct.product.name,
              status: product.receiptProduct.receipt.status,
              createdAt: format(parseISO(product.createdAt), "dd/MM/yyyy"),
              actions: [
                {
                  name: "Movimentar",
                  icon: <BiTransfer size={16} />,
                  action: () => handleMoveProduct(product),
                },
              ],
            }))}
          />
          <Pagination
            totalPage={data.stockProducts.totalPages}
            pageIndex={currentPage}
            setPage={setCurrentPage}
          />
        </>
      )}
    </MainContainer>
  )
}

export default PurchasedProducts;
