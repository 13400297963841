import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import { FormContainer } from 'components/Forms/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import React from 'react';
import colors from 'shared/utils/constants/colors';
import { GET_PURCHASED_PRODUCTS, MOVE_PRODUCT } from '../constants/graphQL';
import { ModalButtonsWrapper, ModalHeader } from '../constants/styles';
import { IMoveProductModalProps } from '../constants/types';
import MoveProductForm from '../forms/MoveProduct';
import { MoveProductFormId } from '../forms/constants';

function MoveProductModal({
  isOpen,
  onClose,
  product,
}: IMoveProductModalProps) {
	const [moveProduct, { loading }] = useMutation(MOVE_PRODUCT, {
		refetchQueries: [GET_PURCHASED_PRODUCTS]
	});

  return (
    <Modal visible={isOpen} closeModal={onClose}>
			<ModalHeader>
				<Text
					text="Movimentar produto"
					color={colors.argon.darkBlue}
					fontFamily="Open Sans"
					size={17}
					weight="600"
				/>
			</ModalHeader>
      <FormContainer>
				<MoveProductForm
					onSubmit={(input) => moveProduct({ variables: { input }})}
					product={product}
					onClose={onClose}
				/>
      </FormContainer>
      <ModalButtonsWrapper>
        <Button
					text="Cancelar"
					onClick={onClose}
				/>
				<Button
					text="Salvar"
					type="submit"
					form={MoveProductFormId}
					loading={loading}
				/>
      </ModalButtonsWrapper>
    </Modal>
  )
}

export default MoveProductModal;
