import { ApolloError } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FormRow } from 'components/Forms/styles';
import TextInput from 'components/TextInput';
import { ICreateTruckFormData, ICreateTruckFormProps } from 'pages/StockTrucks/constants/types';
import React, { useRef } from 'react';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { ValidationError } from 'yup';
import { CreateTruckFormId, CreateTruckFormValidationSchema } from './constants';

function CreateTruckForm({
  onSubmit
}: ICreateTruckFormProps) {
  const createTruckFormRef = useRef<FormHandles>(null);

  const validateFields = async (
    payload: ICreateTruckFormData,
    options: { reset: () => void }
  ) => {
    try {
      await CreateTruckFormValidationSchema.validate(payload, { abortEarly: false });
      await onSubmit(payload);
      options.reset();
      createTruckFormRef.current?.setErrors({});
      launchToast("Truck criado com sucesso!", "success");
    } catch (error) {
      if (error instanceof ValidationError) {
        const errors = getValidationErrors(error);
        createTruckFormRef.current?.setErrors(errors);
      } else if (error instanceof ApolloError) {
        launchToast(error.message, "error");
      } else {
        launchToast("Erro desconhecido. Contate o suporte", "error");
      }
    }
  }

  return (
    <Form
      onSubmit={validateFields}
      ref={createTruckFormRef}
      id={CreateTruckFormId}
    >
      <FormRow>
        <TextInput
          label="nome"
          name="name"
          placeholder="Digite o nome"
        />
        <TextInput
          label="placa"
          name="licensePlate"
          placeholder="Digite a placa"
        />
        <TextInput
          label="modelo do gerador"
          name="generatorModel"
          placeholder="Digite o modelo do gerador"
        />
      </FormRow>
    </Form>
  )
}

export default CreateTruckForm;
