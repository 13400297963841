import * as Yup from "yup";

export const MoveProductValidationSchema = Yup.object().shape({
  sourceId: Yup.string()
    .typeError("Origem é obrigatório")
    .required("Origem é obrigatório"),
  recipientId: Yup.string()
    .typeError("Escolha um destino pro produto")
    .required("Escolha um destino pro produto")
    .notOneOf([Yup.ref('sourceId')], 'Destino não pode ser igual a origem'),
  movementDate: Yup.date()
    .typeError("Escolha uma data")
    .required("Data da movimentação é obrigatória"),
  receiptProductId: Yup.number()
    .typeError("Produto é obrigatório")
    .required("Produto é obrigatório"),
  quantity: Yup.number()
    .typeError("Quantidade é obrigatória")
    .min(1, "Quantidade não pode ser 0 ou negativa")
    .required("Quantidade é obrigatória")
});

export const MoveProductFormId = "move-product-form";
