import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: "calc(100% - 20px)";
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06),
    0px 0px 0px 1px rgba(236, 236, 238, 1);
  margin: 12px;
  padding: 0 8px 12px;
  border-radius: 12px;
`;

export const FormRow = styled.div`
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-left: 14px;
    margin-bottom: 24px;
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
`;
