import { useQuery } from '@apollo/client';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Pagination from 'components/Pagination';
import { PanelHeader } from 'components/PanelHeader';
import Table from "components/Table";
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { stockMovementsColumnsHeader } from './constants';
import { GET_MOVEMENTS } from './constants/graphQL';
import { IStockMovementResponse } from './constants/types';

function StockMovements() {
  const [paginationLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, loading } = useQuery<IStockMovementResponse>(GET_MOVEMENTS, {
    variables: {
      input: {
        pagination: {
          limit: paginationLimit,
          page: currentPage,
        }
      }
    }
  });

  return (
    <MainContainer>
      <PanelHeader
        title="Movimentações"
        isFilterModalVisible={false}
      />
      {loading && <Loading />}
      {data && (
        <>
          <Table
            headers={stockMovementsColumnsHeader}
            data={data.stockMovements.extendedMovements.map((movement) => ({
              ...movement,
              product: movement.receiptProduct.product.name,
              movementDate: format(parseISO(movement.movementDate), "dd/MM/yyyy"),
            }))}
          />
          <Pagination
            totalPage={data.stockMovements.totalPages}
            pageIndex={currentPage}
            setPage={setCurrentPage}
          />
        </>
      )}
    </MainContainer>
  )
}

export default StockMovements;
