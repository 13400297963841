import { ApolloError, useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import CreateAddressForm from 'components/Forms/CreateAddress';
import { FormField, FormRow } from 'components/Forms/styles';
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { GET_PROFESSIONALS } from 'pages/StockWarehouses/constants/graphQL';
import { ICreateWarehouseFormData, ICreateWarehouseFormProps, IProfessionalsResponse } from 'pages/StockWarehouses/constants/types';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { extractNumbersFromMaskedStrings } from 'shared/utils/masks';
import { ValidationError } from 'yup';
import { CreateWarehouseFormId, CreateWarehouseFormValidationSchema } from './constants';

function CreateWarehouseForm({
  onSubmit
}: ICreateWarehouseFormProps) {
  const createWarehouseFormRef = useRef<FormHandles>(null);

  const history = useHistory();

  const [searchProfessionalInput, setSearchProfessionalInput] = useState<string>("");

  const { data } = useQuery<IProfessionalsResponse>(GET_PROFESSIONALS, {
    variables: {
      filters: {
        pagination: {
          limit: 10,
          page: 1
        },
        where: {
          status: true,
          name: searchProfessionalInput
        }
      }
    }
  });

  const validateFields = async (
    payload: ICreateWarehouseFormData,
    options: { reset: () => void }
  ) => {
    try {
      payload.address.cep = extractNumbersFromMaskedStrings(payload.address.cep);
      await CreateWarehouseFormValidationSchema.validate(payload, { abortEarly: false });
      await onSubmit(payload);
      options.reset();
      createWarehouseFormRef.current?.setErrors({});
      launchToast("Armazem criado com sucesso!", "success");
      history.goBack();
    } catch (error) {
			if (error instanceof ValidationError) {
				const errors = getValidationErrors(error);
				createWarehouseFormRef.current?.setErrors(errors);
			} else if (error instanceof ApolloError) {
				launchToast(error.message, "error");
			} else {
				launchToast("Erro desconhecido. Contate o suporte", "error");
			}
    }
  }

  return (
    <Form
      onSubmit={validateFields}
      ref={createWarehouseFormRef}
      id={CreateWarehouseFormId}
    >
      <CreateAddressForm formRef={createWarehouseFormRef} />
      <FormRow>
        <TextInput
          label="nome"
          name="name"
          placeholder="Digite o nome do armazem"
        />
        <FormField>
          <Label>Profissional responsável</Label>
          <Select
            name="userResponsibleId"
            placeholder="Digite o nome"
            options={data?.professionals.map((professional) => ({
              label: `${professional.user.name} - ${professional.user.phone}`,
              value: professional.id
            }))}
            onInputChange={setSearchProfessionalInput}
          />
        </FormField>
      </FormRow>
    </Form>
  )
}

export default CreateWarehouseForm;
